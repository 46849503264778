import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
// ^^ only works at start of text, try one of these instead:
// https://github.com/moroshko/autosuggest-highlight/pulls
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import debounce from 'lodash.debounce'
import { withStyles } from '@material-ui/core/styles';

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  // TODO:  update match to search within string...look at the repo's issues and PRs
  const matches = match(suggestion.apn, query);
  const parts = parse(suggestion.apn, matches);

  if (+suggestion.count === 0) {
    return (
      <MenuItem selected={isHighlighted} component="div">
        No results.
      </MenuItem>
    )
  }
  if (suggestion.count) {
    return (
      <MenuItem selected={isHighlighted} component="div">
        Too many results...currently {suggestion.count}
      </MenuItem>
    )
  }

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          ),
        )}:  {`${suggestion.own_name1}`}
      </div>
    </MenuItem>
  );
}

function getSuggestionValue(suggestion) {
  console.log('get value PARCELS', suggestion)
  // TODO:  show multiple owners?
  const { apn, own_name1, situs_addr, situs_stre, situs_suff, situs_comm, situs_zip } = suggestion
  const addressLine1 = `${situs_addr} ${situs_stre} ${situs_suff}`
  const addressLine2 = `${situs_comm}, CA ${situs_zip}`
  const {
    latitude,
    longitude,
    parcelid: parcelId
  } = suggestion
  const location = [+longitude, +latitude]
  const popupContent = {
    coordinates: location,
    info: {
      apn,
      address: [addressLine1, addressLine2],
      owner: own_name1
    }
  }
  this.setState({
    location,
    parcelId,
    popupContent
  })
  if (apn && own_name1) {
    return `${apn}:  ${own_name1}`
  }
  return ''
}

const styles = theme => ({
  root: {
    height: 40,
    flexGrow: 1,
    maxWidth: '40em',
    margin: '1rem auto'
  },
  container: {
    position: 'relative'
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  suggestionsContainerOpenAbove: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    bottom: '120px'
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

class AutocompleteParcels extends React.Component {
  state = {
    single: '',
    suggestions: [],
    location: [],
    popupContent: null
  };

  handleSuggestionsFetchRequested = debounce(({ value, reason }) => {
    // console.log('fetch suggestions', value, reason)
    // fetch(`http://127.0.0.1:3434/parcel-search/${value}`)
    fetch(`https://sandiegoparcels.com/app2/parcel-search/${value}`)
      .then(response => response.json())
      .then(rows => {
        this.setState({ suggestions: rows })
      })
  }, 100);

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleChange = name => (event, { newValue }) => {
    this.setState({
      [name]: newValue,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.location && this.state.location !== prevState.location) {
      console.log('calling center map', this.state.location)
      this.props.centerMap(this.state.location, 16, this.state.parcelId)
      this.props.updatePopup(this.state.popupContent)
    }
  }

  render() {
    const { classes } = this.props;

    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue: getSuggestionValue.bind(this),
      renderSuggestion,
    };

    const suggestionsContainerOpen = (this.props.width !== 'xs')
      ? classes.suggestionsContainerOpen
      : classes.suggestionsContainerOpenAbove

    return (
      <div className={classes.root}>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            placeholder: 'Start typing an APN (numbers only)',
            value: this.state.single,
            onChange: this.handleChange('single'),
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={options => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
        />
      </div>
    );
  }
}

AutocompleteParcels.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AutocompleteParcels);
