import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
// ^^ only works at start of text, try one of these instead:
// https://github.com/moroshko/autosuggest-highlight/pulls
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import debounce from 'lodash.debounce'
import { withStyles } from '@material-ui/core/styles';
import { mapboxAddressSearch } from '../api'

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  // TODO:  update match to search within string...look at the repo's issues and PRs
  const matches = match(suggestion.place_name, query);
  const parts = parse(suggestion.place_name, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          )
        )}
      </div>
    </MenuItem>
  );
}

const styles = theme => ({
  root: {
    height: 40,
    flexGrow: 1,
    maxWidth: '40em',
    margin: '1rem auto'
  },
  container: {
    position: 'relative'
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  suggestionsContainerOpenAbove: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    bottom: '120px'
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

class AutocompleteAddresses extends React.Component {
  state = {
    single: '',
    suggestions: []
  };

  function(place) {
    return
  }

  handleSuggestionsFetchRequested = debounce(async ({ value, reason }) => {
    const results = await mapboxAddressSearch(value)
    this.setState({ suggestions: results.features })
  }, 200);

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleChange = name => (event, { newValue }) => {
    this.setState({
      [name]: newValue,
    });
  };

  render() {
    const { classes } = this.props;

    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue: suggestion => {
        if (suggestion.geometry.type === 'Point') {
          const { coordinates } = suggestion.geometry
          console.log('address', suggestion)
          this.props.centerMap(coordinates, 16)
          this.props.updatePopup({
            coordinates,
            info: {
              address: [suggestion.place_name]
            }
          })
        }
        return suggestion.place_name
      },
      renderSuggestion,
    };

    const suggestionsContainerOpen = (this.props.width !== 'xs')
      ? classes.suggestionsContainerOpen
      : classes.suggestionsContainerOpenAbove

    return (
      <div className={classes.root}>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            placeholder: 'Type an address',
            value: this.state.single,
            onChange: this.handleChange('single'),
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={options => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
        />
      </div>
    );
  }
}

AutocompleteAddresses.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AutocompleteAddresses);
