import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AppBar from '@material-ui/core/AppBar'
import withWidth from '@material-ui/core/withWidth'
import withRoot from '../withRoot'
import ReactMapGL, { FlyToInterpolator, Popup } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import '../styles/main.css'
import mapStyle from '../mapStyle'
import Tabs from '../components/Tabs'
import {
  parcelByParcelId
} from '../api'
import keys from '../keys'

const PARCELS_POLYGON = 'san_diego_parcels_layer_polygon'
// const PARCELS_LINE = 'san_diego_parcels_layer_line'
const PARCELS_SELECTED = 'san_diego_parcels_layer_polygon_selected'

const noFeatures = ['in', 'PARCELID', '']

const styles = theme => ({
  root: {
    textAlign: 'center',
    paddingLeft: theme.spacing.unit * 5,
    paddingTop: theme.spacing.unit * 5,
    position: 'absolute',
    top: 0,
    left: 0
  },
  narrow: {
    textAlign: 'center',
    paddingLeft: 0,
    paddingTop: 0,
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%'
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  }
});

let mapStartLatitude = 32.75756
let mapStartLongitude = -117.13796
let mapStartZoom = 16

if (window.location && window.location.hash) {
  let zyx = window.location.hash.replace(/^#/, '').split('/').map(w => parseFloat(w))
  if (zyx.length === 3) {
    mapStartLatitude = zyx[1]
    mapStartLongitude = zyx[2]
    mapStartZoom = zyx[0]
  }
}

class Index extends React.Component {
  state = {
    mapStyle,
    viewport: {
      // north side of balboa park
      // latitude: 32.7410,
      // longitude: -117.1406,
      latitude: mapStartLatitude,
      longitude: mapStartLongitude,
      zoom: mapStartZoom,
      bearing: 0,
      pitch: 0,
      width: '100%',
      height: '100%'
    }
  };

  centerMap = (nextCenter, zoom = 10, selectedParcel) => {
    // console.log('next center', nextCenter)
    console.log('selected parcel', selectedParcel)
    const nextViewport = {
      ...this.state.viewport,
      latitude: nextCenter[1],
      longitude: nextCenter[0],
      zoom,
      transitionInterpolator: new FlyToInterpolator(),
      transitionDuration: 2000
    }
    if (!selectedParcel) {
      this.setState({
        mapStyle: this._clearSelection(),
        viewport: nextViewport
      })
    } else {
      this.setState({
        mapStyle: this._updateSelection(+selectedParcel).style,
        viewport: nextViewport
      })
    }
  }

  updatePopup = (popupContent) => {
    this.setState({
      popupContent
    })
  }

  _mapClick = async e => {
    if (e.features.length > 0) {
      // console.log('e', e)
      // console.log('feature count on click (more than 1?)', e.features.length)
      const { PARCELID } = e.features[0].properties
      // console.log('clicked parcel', PARCELID)
      this.setState({
        popupContent: {
          coordinates: e.lngLat,
          info: { PARCELID }
        }
      })

      // add a selection layer...
      const updated = this._updateSelection(PARCELID)
      const parcelRecords = await parcelByParcelId(PARCELID)
      if (parcelRecords.length > 0) {
        console.log('parcel records', parcelRecords)
        // TODO:  dupe of AutocompleteParcels code...move to different module
        const { apn, own_name1, situs_addr, situs_stre, situs_suff, situs_comm, situs_zip } = parcelRecords[0]
        // TODO:  handle multiple parcels?
        const addressLine1 = `${situs_addr || ''} ${situs_stre || ''} ${situs_suff || ''}`
        const addressLine2 = `${situs_comm || ''}, CA ${situs_zip || ''}`
        this.setState({
          mapStyle: updated.style,
          popupContent: {
            coordinates: e.lngLat,
            info: {
              apn,
              address: [addressLine1, addressLine2],
              owner: own_name1,
              PARCELID
            },
            parcelRecords
          }
        })
      }
    }
  }

  _updateSelection (id) {
    const parcelsIndex = this._findLayerIndex(PARCELS_POLYGON)
    const selectedParcelsIndex = this._findLayerIndex(PARCELS_SELECTED)
    const nextStyle = this._cloneStyle()
    let result = { style: nextStyle, madeChanges: false }
    if (parcelsIndex > -1 && id) {
      nextStyle.layers[parcelsIndex].filter = ['!=', 'PARCELID', id]
      let selectedParcels
      if (selectedParcelsIndex > -1) {
        selectedParcels = nextStyle.layers[selectedParcelsIndex]
      } else {
        selectedParcels = JSON.parse(JSON.stringify(nextStyle.layers[parcelsIndex]))
        selectedParcels.id = `${PARCELS_POLYGON}_selected`
        selectedParcels.paint = {
          'fill-opacity': 1,
          'fill-color': '#ffff31'
        }
        nextStyle.layers.splice(parcelsIndex - 5, 0, selectedParcels)
      }
      // console.log('next style parcels', nextStyle.layers[parcelsIndex])
      selectedParcels.filter = ['in', 'PARCELID', id]
      result.madeChanges = true
    }
    return result
  }

  _clearSelection () {
    const nextStyle = this._cloneStyle()
    nextStyle.layers.forEach(layer => {
      if (layer.id === PARCELS_SELECTED) {
        layer.filter = noFeatures
      }
      if (layer.id === PARCELS_POLYGON) {
        delete layer.filter
      }
    })
    return nextStyle
  }

  _cloneStyle () {
    return JSON.parse(JSON.stringify(this.state.mapStyle))
  }

  _renderPopup () {
    const { popupContent } = this.state
    // console.log('render popup', popupContent)
    return popupContent && popupContent.coordinates && (
      <Popup
        longitude={popupContent.coordinates[0]}
        latitude={popupContent.coordinates[1]}
        closeOnClick={false}
        dynamicPosition={this.props.width !== 'xs'}
        onClose={() => {
          this.setState({
            mapStyle: this._clearSelection(),
            popupContent: null
          })
        }}
        captureScroll={true}
      >
        <Typography>
          <span>
            {popupContent.info.PARCELID &&
              <span style={{ paddingBottom: '10px' }}>
                Parcel ID:  {popupContent.info.PARCELID}
              </span>
            }
            {popupContent.info.address &&
              <span style={{ paddingBottom: '10px' }}>
                Address:<br />{this.state.popupContent.info.address.map((line, index) => (
                  <span key={index}>{line}</span>
                ))}
              </span>
            }
            {popupContent.parcelRecords &&
              <span>
                <span>
                  Owners:
                </span>
                <span className={'scroll'}>
                  {popupContent.parcelRecords.map((rec, index) => {
                    return <span key={index}>{rec.apn}:  <strong>{rec.own_name1}</strong></span>
                  })}
                </span>
              </span>
            }
            {!popupContent &&
              <span style={{ paddingBottom: '10px' }}>
                Nothing to see here.
              </span>
            }
          </span>
        </Typography>
      </Popup>
    )
  }

  _findLayerIndex = (id) => {
    return this.state.mapStyle.layers.findIndex(layer => layer.id === id)
  }

  _getTabs = () => {
    const { classes, width } = this.props;
    if (width !== 'xs') {
      return (
        <div className={(width !== 'xs') ? classes.root : classes.narrow }>
          <Tabs
            centerMap={this.centerMap}
            updatePopup={this.updatePopup}
            width={width}
          />
        </div>
      )
    } else {
      return (
        <AppBar position="fixed" className={classes.appBar} style={{ height: '20%' }}>
          <Tabs
            centerMap={this.centerMap}
            updatePopup={this.updatePopup}
            width={width}
          />
        </AppBar>
      )
    }
  }

  render() {
    return (
      <div style={{height: '100vh', width: '100vw'}}>
        <ReactMapGL
          mapStyle={this.state.mapStyle}
          onViewportChange={viewport => this.setState({viewport})}
          mapboxApiAccessToken={keys.mapbox}
          clickRadius={1}
          interactiveLayerIds={[PARCELS_POLYGON]}
          onNativeClick={this._mapClick}
          {...this.state.viewport}
          ref={(gl) => this._map = gl}
          attributionControl={false}
          height={this.props.width !== 'xs' ? '100%' : '80%'}
          mapOptions={{ hash: true }}
        >
          {this._renderPopup()}
          {/* {this.state.showCenterMarker
            && <Layer
              type='circle'
              id='marker'
              paint={{ 'circle-radius': 12, 'circle-opacity': 0.8, 'circle-color': '#55c87a' }}
            >
              <Feature coordinates={this.state.mapCenter}/>
            </Layer>
          } */}
        </ReactMapGL>

        {this._getTabs()}
     </div>
    );
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRoot(withWidth()(withStyles(styles)(Index)));
