import keys from './keys'

const mapboxSearch = `https://api.mapbox.com/geocoding/v5/mapbox.places/`
const mapboxToken = `?access_token=${keys.mapbox}`

export async function mapboxAddressSearch (value) {
  const url = `${mapboxSearch}${encodeURIComponent(value)}.json${mapboxToken}`
  const results = await fetch(url)
      .then(response => response.json())
      .then(rows => rows)
  return results
}

export async function parcelByParcelId (id) {
  // const url = `http://127.0.0.1:3434/parcel-id/${id}`
  const url = `https://sandiegoparcels.com/app2/parcel-id/${id}`
  const results = await fetch(url)
    .then(response => response.json())
    .then(rows => rows)
  return results
}
