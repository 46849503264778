import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import AutocompleteParcels from '../components/AutocompleteParcels'
import AutocompleteAddresses from '../components/AutocompleteAddresses'

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 2, height: 100 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500
  },
  narrow: {
    backgroundColor: theme.palette.background.paper,
    width: '100%'
  }
});

class FullWidthTabs extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  render() {
    const { classes, theme, width } = this.props;
    const { value } = this.state;

    return (
      <div className={(width !== 'xs') ? classes.root : classes.narrow}>
        <AppBar position="static" color="default">
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="APN Search" />
            <Tab label="Address Search" />
          </Tabs>
        </AppBar>

        {value === 0
          && <TabContainer dir={theme.direction}>
            <AutocompleteParcels
              centerMap={this.props.centerMap}
              updatePopup={this.props.updatePopup}
              width={width}
            />
          </TabContainer>
        }

        {value === 1
          && <TabContainer dir={theme.direction}>
            <AutocompleteAddresses
              centerMap={this.props.centerMap}
              updatePopup={this.props.updatePopup}
              width={width}
            />
          </TabContainer>
        }
      </div>
    );
  }
}

FullWidthTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(FullWidthTabs);
